import { Button, Menu, MenuButton, MenuList } from '@chakra-ui/react'
import { DOPPEL_TERMINAL_BLUE, DOPPEL_TEXT_BLACK } from '../../utils/style'
import React from 'react'

const BulkActionButton = ({ children, menuListItems = [], ...props }) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        bgColor={DOPPEL_TERMINAL_BLUE}
        fontSize="11"
        h="30px"
        marginRight={2}
        minWidth="80px"
        textColor={DOPPEL_TEXT_BLACK}
        {...props}
      >
        {children}
      </MenuButton>

      {menuListItems && (
        <MenuList maxH="300px" overflowY="auto">
          {menuListItems.map((item, index) => (
            <React.Fragment key={item.key || index}>{item.content}</React.Fragment>
          ))}
        </MenuList>
      )}
    </Menu>
  )
}

export default BulkActionButton
