import { MenuItem } from '@chakra-ui/react'
import { DOPPEL_INTERNAL_PURPLE, DOPPEL_TEXT_WHITE } from '@/utils/style'
import BulkActionButton from '@/components/tables/bulk_action_button'
import { getDoppelReportUrl, getExternalReportId } from '@/utils/reports/report_utils'
import { ProductType } from '@/generated/enums'

const BulkCopyButton = ({ selectedRows }) => {
  const menuListItems = [
    {
      key: 'copy-entity-values',
      label: 'Copy Entity URLs',
      accessor: (alert) => alert.flagged_url,
    },
    {
      key: 'copy-ids',
      label: 'Copy Alert IDs',
      accessor: (alert) =>
        getExternalReportId(alert.organization?.abbr_name, alert.external_id),
    },
    {
      key: 'copy-doppel-links',
      label: 'Copy Alert Links',
      accessor: (alert) => {
        const isSuspiciousEmails =
          alert.platform_subtype?.product === ProductType.SUSPICIOUS_EMAILS
        return getDoppelReportUrl(alert, alert.organization, isSuspiciousEmails)
      },
    },
  ].map(({ key, label, accessor }) => {
    const onClick = () => {
      const valuesToCopy = selectedRows.map((row) => accessor(row.original))
      navigator.clipboard.writeText(valuesToCopy.join('\n'))
    }
    return {
      key: key,
      content: <MenuItem onClick={onClick}>{label}</MenuItem>,
    }
  })
  return (
    <BulkActionButton
      bgColor={DOPPEL_INTERNAL_PURPLE}
      textColor={DOPPEL_TEXT_WHITE}
      marginRight={0}
      menuListItems={menuListItems}
    >
      Bulk Copy
    </BulkActionButton>
  )
}

export default BulkCopyButton
