import { Box, Flex, Text, Checkbox } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

import {
  ReportStatus,
  ReportType,
  Classification,
  SCORE_ENABLED_REPORT_TYPES,
  CLASSIFICATION_TO_LABEL,
  DarkWebPlatforms,
  SEVERITY_TO_LABEL,
  ReportFilterType,
} from '../../utils/constants'
import TablePreview, {
  TablePreviewSize,
  previewSizeToWidth,
} from '../tables/table_preview'
import { getFlaggedPreviewImage, WEB2_SCREENSHOTS_BUCKET } from '../../utils/web2'
import DoppelLink from '../report_detail/doppel_link'
import TableTimestamp from '../tables/table_timestamp'
import ReportEnforcementStatus from './report_enforcement_status'
import {
  getColorForSeverity,
  getColorForSpoofStatus,
} from './spoof_status_tag_with_menu'
import RichText from '../shared/rich_text'
import {
  getContextAroundHighlightedContent,
  getStringAfterFirstHyphen,
} from '../../utils/string_utils'
import {
  getExternalReportId,
  getReportSourceDisplay,
  getVersionedScreenshotInfo,
  REPORT_TYPES_WITH_PLATFORMS,
  PRODUCT_TYPE_TO_REPORT_TYPE_MAP,
} from '../../utils/reports/report_utils'
import { shouldDefaultToHttp } from '../../utils/feature_flags'
import CountryCode from '../shared/country_code'
import { DoppelTag } from '../shared/doppel_tag'

import parsePhoneNumberFromString from 'libphonenumber-js'
import { sortTagsAlphabetically } from '../../utils/sort'
import TagGroup from '../shared/tag_group'
import { getPlatformSubtypeDisplayValue } from '@/utils/reports/platform_subtype_utils'
import SpoofReportsTableLastReportedDateCell from './spoof_reports_table_last_reported_date_cell'
import { constructGcsUrl } from '@/utils/gcs_utils'
import { AlertTableColumn } from '@/generated/enums'
import { SortType } from '@/utils/reports/report_sorting_mechanism'

export type ColumnConfig = {
  id: AlertTableColumn
  isColumnViewable?: boolean // default true -- user can view
  isColumnInitiallyHidden?: boolean // default false -- initially visible
  sortType?: SortType | null // default undefined -- not sortable
  filterType?: ReportFilterType | null // default undefined -- not filterable
  // these are passed directly to react-table
  accessor: string | ((alert: any) => any)
  Cell: (cellInfo: any) => ReactNode
}

export const getCommonReportColumns = (
  reportType: ReportType,
  reportStatus: ReportStatus,
  isEmployeeView: boolean,
): ColumnConfig[] => {
  return [
    {
      id: AlertTableColumn.STATE,
      accessor: 'spoof_status',
      isColumnViewable:
        reportType !== ReportType.DARK_WEB &&
        reportStatus !== ReportStatus.ENUM_RESOLVED,
      sortType: SortType.spoof_status,
      filterType: ReportFilterType.Classification,
      Cell: (cellInfo) => (
        <DoppelTag
          bgColor={getColorForSpoofStatus(cellInfo.value)}
          color={'#FFFFFF'}
          tag={CLASSIFICATION_TO_LABEL[cellInfo.value]}
        />
      ),
    },
    {
      id: AlertTableColumn.SEVERITY,
      accessor: 'severity',
      sortType: SortType.severity,
      filterType: ReportFilterType.Severity,
      isColumnViewable:
        reportType !== ReportType.DARK_WEB &&
        reportStatus !== ReportStatus.ENUM_RESOLVED,
      Cell: ({ value, row }) =>
        row.original.spoof_status === Classification.ACTIVE && value ? (
          <DoppelTag
            bgColor={getColorForSeverity(value)}
            color={'#FFFFFF'}
            tag={SEVERITY_TO_LABEL[value]}
          />
        ) : null,
    },
    {
      id: AlertTableColumn.SCORE,
      accessor: 'score',
      sortType: SortType.score,
      // TODO: remove SCORE_ENABLED_REPORT_TYPES filter once we add score to all other report types
      isColumnViewable: isEmployeeView && SCORE_ENABLED_REPORT_TYPES.has(reportType),
      Cell: (cellInfo) => <Text noOfLines={1}>{cellInfo.value?.toFixed(2)}</Text>,
    },
    {
      id: AlertTableColumn.BRAND,
      sortType: SortType.brand,
      filterType: ReportFilterType.Brand,
      accessor: (a) => a.spoofing_entity?.entity_name || '',
      Cell: (cellInfo) => (
        <Text noOfLines={1} width="100px">
          {cellInfo.value}
        </Text>
      ),
    },
    notesColumn,
    generateTagsColumn(isEmployeeView),
    {
      id: AlertTableColumn.ASSIGNEE,
      sortType: SortType.internal_alert_assignee,
      filterType: ReportFilterType.Assignee,
      isColumnViewable: isEmployeeView,
      accessor: (a) => a.internal_alert_assignee?.email || '',
      Cell: (cellInfo) => <Text noOfLines={1}>{cellInfo.value}</Text>,
    },
  ]
}
export const getSpoofingReportColumns = (
  reportType: ReportType,
  reportStatus: ReportStatus,
  isOrgUnifiedView: boolean,
  orgId: string,
  isEmployeeView: boolean,
  orgData: any,
): ColumnConfig[] => {
  if (reportType == ReportType.DARK_WEB) return []
  const reportSpecificColumns = [
    dateCreatedColumn,
    lastActivityColumn,
    {
      id: AlertTableColumn.PRODUCT,
      accessor: 'platform_subtype',
      isColumnViewable: !reportType,
      sortType: SortType.platform_subtype,
      Cell: (cellInfo) => (
        <Text noOfLines={1} width="100px">
          {PRODUCT_TYPE_TO_REPORT_TYPE_MAP[cellInfo.value?.product]}
        </Text>
      ),
    },
    {
      id: AlertTableColumn.ORGANIZATION,
      accessor: 'organization',
      sortType: SortType.organization,
      isColumnViewable: isOrgUnifiedView,
      Cell: (cellInfo) => (
        <Text fontSize={13} noOfLines={2} width="90px">
          {cellInfo.value.name}
        </Text>
      ),
    },
    idColumn,
    {
      id: AlertTableColumn.URL,
      accessor: (a) => {
        return a
      },
      sortType: SortType.flagged_url,
      isColumnViewable: reportType !== ReportType.TELCO,
      Cell: (cellInfo) => {
        const url = cellInfo.value.flagged_url
        return (
          <Box width={175}>
            <DoppelLink
              disableLink={true}
              href={cellInfo.value.unsanitized_url || url}
              isDefanged={true}
              name={url}
              noOfLines={3}
              shouldDefaultToHttp={shouldDefaultToHttp(orgId)}
            />
          </Box>
        )
      },
    },
    {
      id: AlertTableColumn.PHONE_NUMBER,
      accessor: (a) => {
        return a.spoof_matches[0]?.phone_number
      },
      sortType: SortType.flagged_url,
      isColumnViewable: reportType === ReportType.TELCO,
      Cell: (cellInfo) => (
        <Text noOfLines={1} width="150px">
          {cellInfo.value?.number}
        </Text>
      ),
    },
    {
      id: AlertTableColumn.PROVIDER,
      accessor: (a) => {
        return a.spoof_matches[0]?.phone_number
      },
      isColumnViewable: reportType === ReportType.TELCO,
      Cell: (cellInfo) => (
        <Text noOfLines={1} width="150px">
          {cellInfo.value?.telco_provider}
        </Text>
      ),
    },
    {
      id: AlertTableColumn.DATE_REPORTED,
      accessor: 'last_reported_at',
      sortType: SortType.last_reported_at,
      isColumnViewable: reportStatus === ReportStatus.ENUM_REPORTED,
      Cell: (cellInfo) => (
        <SpoofReportsTableLastReportedDateCell
          isEmployeeView={isEmployeeView}
          lastReportedAt={cellInfo.value}
        />
      ),
    },
    {
      id: AlertTableColumn.LAST_RESOLVED_AT,
      accessor: 'last_resolved_at',
      sortType: SortType.last_resolved_at,
      isColumnViewable: reportStatus === ReportStatus.ENUM_RESOLVED,
      Cell: (cellInfo) => <TableTimestamp date={cellInfo.value} />,
    },
    previewColumn,
    {
      id: AlertTableColumn.PLATFORM_SUBTYPE,
      accessor: 'platform_subtype',
      sortType: SortType.platform_subtype,
      isColumnViewable: REPORT_TYPES_WITH_PLATFORMS.includes(reportType),
      Cell: (cellInfo) => (
        <Text noOfLines={1} width="70px">
          {getPlatformSubtypeDisplayValue(cellInfo.value?.name, reportType)}
        </Text>
      ),
    },
    {
      id: AlertTableColumn.ENFORCEMENTS,
      accessor: 'enforcement_requests',
      isColumnViewable: reportStatus === ReportStatus.ENUM_REPORTED,
      Cell: (cellInfo) => (
        <Box width="100px">
          <ReportEnforcementStatus enforcement_requests={cellInfo.value} />
        </Box>
      ),
    },
    {
      id: AlertTableColumn.FOLLOWERS,
      accessor: (a) => {
        return a.spoof_matches[0]?.social_media_data?.num_followers
      },
      sortType: SortType.num_followers,
      isColumnViewable: reportType === ReportType.SOCIAL_MEDIA,
      Cell: (cellInfo) => (
        <Text noOfLines={1} width="100px">
          {cellInfo.value}
        </Text>
      ),
    },
    {
      id: AlertTableColumn.COUNTRY,
      accessor: (a) => a.first_domain_data[0]?.full_url?.domain_data || a.domain_data,
      isColumnViewable: reportType === ReportType.DOMAINS && isEmployeeView,
      Cell: (cellInfo) =>
        cellInfo.value?.country_code && (
          <Box width="35px">
            <CountryCode
              countryCode={cellInfo.value.country_code}
              tooltip={cellInfo.value.country_code}
            />
          </Box>
        ),
    },
    {
      id: AlertTableColumn.NAMESERVERS,
      accessor: (a) => a.first_domain_data[0]?.full_url?.domain_data || a.domain_data,
      isColumnViewable: reportType === ReportType.DOMAINS,
      Cell: (cellInfo) => {
        if (!cellInfo.value?.raw_data) {
          return <></>
        }
        try {
          const parsedData = JSON.parse(cellInfo.value?.raw_data)
          if (
            !parsedData?.whois?.name_servers ||
            !Array.isArray(parsedData?.whois?.name_servers)
          ) {
            return <></>
          }

          return parsedData.whois.name_servers.map((nameServer, index) => (
            <Text fontSize="11" key={index} noOfLines={1} width="150px">
              {nameServer}
            </Text>
          ))
        } catch (e) {
          return <></>
        }
      },
    },
    {
      id: AlertTableColumn.MX,
      accessor: (a) => a.first_domain_data[0]?.full_url?.domain_data || a.domain_data,
      isColumnViewable: reportType === ReportType.DOMAINS,
      Cell: (cellInfo) => {
        const rawData = cellInfo.value?.raw_data

        if (!rawData) {
          return <></>
        }

        const parsedData = JSON.parse(rawData)
        const mxRecords = parsedData?.dns?.MX

        if (!Array.isArray(mxRecords) || mxRecords.length === 0) {
          // If there are no MX records, render an unchecked checkbox
          return <></>
        }

        return <Checkbox isChecked={true} />
      },
    },
    {
      id: AlertTableColumn.TELCO_COUNTRY,
      accessor: (a) => a.spoof_matches[0]?.phone_number,
      isColumnViewable: reportType === ReportType.TELCO,
      Cell: (cellInfo) => {
        const { country_code, number: full_number } = cellInfo.value || {}
        if (!country_code || !full_number) return null

        let flagCode = country_code.toLowerCase()
        const phoneNumber = parsePhoneNumberFromString(full_number)
        if (phoneNumber) {
          const country = phoneNumber.country
          flagCode = country?.toLowerCase()
        }

        if (!flagCode) return null

        const toolTip = `${flagCode.toUpperCase()} (${country_code})`

        return <CountryCode countryCode={flagCode} tooltip={toolTip} />
      },
    },
    {
      id: AlertTableColumn.SOURCE,
      filterType: ReportFilterType.Source,
      accessor: (a) => a,
      Cell: (cellInfo) => {
        if (cellInfo?.value == null) {
          return <></>
        }
        return (
          <Text noOfLines={3} width="150px">
            {getReportSourceDisplay(cellInfo.value, isEmployeeView, orgData?.name)}
          </Text>
        )
      },
    },
    {
      id: AlertTableColumn.ESTIMATED_VALUE,
      accessor: 'estimated_usd_price',
      sortType: SortType.estimated_usd_price,
      isColumnViewable: reportType === ReportType.CRYPTO,
      Cell: (cellInfo) => {
        if (!cellInfo?.value) {
          return <>-</>
        }
        return (
          <Text>
            ${cellInfo?.value.toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Text>
        )
      },
    },
  ]
  return [
    ...reportSpecificColumns,
    ...getCommonReportColumns(reportType, reportStatus, isEmployeeView),
  ]
}

export const getDarkWebColumns = (
  reportStatus: ReportStatus,
  reportPlatform: DarkWebPlatforms,
  isEmployeeView: boolean,
): ColumnConfig[] => {
  const darkWebColumns = []

  darkWebColumns.push(
    ...[
      dateCreatedColumn,
      lastActivityColumn,
      {
        id: AlertTableColumn.CRAWLED_AT,
        accessor: (a) => a.spoof_matches[0]?.dark_web?.crawled_at || '',
        sortType: SortType.online_at,
        Cell: (cellInfo) => <TableTimestamp date={cellInfo.value} />,
      },
    ],
  )

  if (reportPlatform === DarkWebPlatforms.CRED_LEAKS) {
    darkWebColumns.push(
      ...[
        {
          id: AlertTableColumn.USER,
          accessor: (a) => a.spoof_matches[0]?.dark_web?.title || '',
          Cell: (cellInfo) => (
            <Text noOfLines={3} width="300px">
              {cellInfo.value.split(':')[0]}
            </Text>
          ),
        },
        {
          id: AlertTableColumn.PASSWORD,
          accessor: (a) => a.spoof_matches[0]?.dark_web?.title || '',
          Cell: (cellInfo) => (
            <Text noOfLines={3} width="200px">
              {cellInfo.value.split(':')[1]}
            </Text>
          ),
        },
        {
          id: AlertTableColumn.CREDENTIAL_URL,
          accessor: (a) => a.spoof_matches[0]?.dark_web?.credential_url || '',
          Cell: (cellInfo) => (
            <Text noOfLines={3} width="400px">
              {cellInfo.value}
            </Text>
          ),
        },
      ],
    )
  }

  if (reportPlatform === DarkWebPlatforms.GENERIC) {
    darkWebColumns.push(
      ...[
        {
          id: AlertTableColumn.HIGHLIGHT,
          accessor: (a) => a.spoof_matches[0]?.dark_web?.body || '',
          Cell: (cellInfo) => (
            <Text noOfLines={3} width="160px">
              <RichText html={getContextAroundHighlightedContent(cellInfo.value)} />
            </Text>
          ),
        },
        {
          id: AlertTableColumn.TITLE,
          accessor: (a) => a.spoof_matches[0]?.dark_web?.title || '',
          sortType: SortType.title,
          Cell: (cellInfo) => (
            <Text noOfLines={3} width="400px">
              {cellInfo.value}
            </Text>
          ),
        },
      ],
    )
  }

  if (reportPlatform === DarkWebPlatforms.CREDIT_CARD_LEAKS) {
    darkWebColumns.push(
      ...[
        {
          id: AlertTableColumn.BIN,
          accessor: (a) => a.spoof_matches[0]?.dark_web?.credit_card?.bin || '',
          sortType: SortType.bin,
          Cell: (cellInfo) => (
            <Text noOfLines={3} width="70px">
              {cellInfo.value}
            </Text>
          ),
        },
        {
          id: AlertTableColumn.CCN,
          accessor: (a) => a.spoof_matches[0]?.dark_web?.credit_card || '',
          Cell: (cellInfo) => {
            const bin = (cellInfo.value?.bin || '').toString() // Convert bin to string
            const ccn = cellInfo.value?.credit_card_number || ''
            // Remove the BIN from the credit card number
            const trimmedCcn = ccn.slice(bin.length)
            // Mask all but the last 4 digits
            const maskedCcn =
              trimmedCcn.slice(0, -4).replace(/\d/g, '*') + trimmedCcn.slice(-4)

            return (
              <Text noOfLines={3} width="80px">
                {maskedCcn}
              </Text>
            )
          },
        },
        {
          id: AlertTableColumn.EXPIRATION_DATE,
          accessor: (a) => a.spoof_matches[0]?.dark_web?.credit_card || '',
          sortType: SortType.expiration_date,
          Cell: (cellInfo) => {
            const expirationDate = cellInfo.value?.expiration_date || ''
            // Extract year and month from the expiration date
            const formattedDate = expirationDate ? expirationDate.slice(0, 7) : ''

            return (
              <Text noOfLines={3} width="100px">
                {formattedDate}
              </Text>
            )
          },
        },
        {
          id: AlertTableColumn.CARDHOLDER_NAME,
          accessor: (a) => a.spoof_matches[0]?.dark_web?.credit_card || '',
          sortType: SortType.cardholder_name,
          Cell: (cellInfo) => {
            const cardholder_name = cellInfo.value?.cardholder_name || ''
            return (
              <Text noOfLines={3} width="100px">
                {cardholder_name}
              </Text>
            )
          },
        },
        {
          id: AlertTableColumn.CARDHOLDER_ADDRESS,
          accessor: (a) => a.spoof_matches[0]?.dark_web?.credit_card || '',
          sortType: SortType.cardholder_address,
          Cell: (cellInfo) => {
            const cardholder_address = cellInfo.value?.cardholder_address || ''
            return (
              <Text noOfLines={3} width="100px">
                {cardholder_address}
              </Text>
            )
          },
        },
      ],
    )
  }

  darkWebColumns.push(
    ...[
      {
        id: AlertTableColumn.NETWORK,
        accessor: (a) => a.spoof_matches[0]?.dark_web?.network || '',
        filterType: ReportFilterType.DarkWebNetwork,
        Cell: (cellInfo) => (
          <Text noOfLines={1} width="100px">
            {cellInfo.value}
          </Text>
        ),
      },
    ],
  )

  if (reportPlatform !== DarkWebPlatforms.CREDIT_CARD_LEAKS) {
    darkWebColumns.push(
      ...[
        {
          id: AlertTableColumn.SOURCE,
          sortType: SortType.dark_web_source,
          filterType: ReportFilterType.DarkWebSource,
          accessor: (a) => a.spoof_matches[0]?.dark_web?.source || '',
          Cell: (cellInfo) => (
            <Text noOfLines={1} width="100px">
              {getStringAfterFirstHyphen(cellInfo.value)}
            </Text>
          ),
        },
      ],
    )
  }

  return [
    ...darkWebColumns,
    ...getCommonReportColumns(ReportType.DARK_WEB, reportStatus, isEmployeeView),
  ]
}

export const getSuspiciousEmailColumns = (isEmployeeView: boolean): ColumnConfig[] => {
  return [
    dateCreatedColumn,
    lastActivityColumn,
    idColumn,
    {
      id: AlertTableColumn.SUBMITTED_BY,
      accessor: (a) => a.spoof_matches[0]?.email?.forward_data?.sender,
      Cell: (cellInfo) => (
        <Text fontSize="10" noOfLines={1} width="150px">
          {cellInfo.value}
        </Text>
      ),
    },
    {
      id: AlertTableColumn.SUBJECT,
      accessor: (a) => a.spoof_matches[0]?.email?.subject,
      Cell: (cellInfo) => (
        <Text fontSize="10" noOfLines={2} width="350px">
          {cellInfo.value}
        </Text>
      ),
    },
    notesColumn,
    generateTagsColumn(isEmployeeView),
    // add other columns later
  ]
}

const getTagsColumn = (reportTags, isEmployeeView: boolean) => {
  const sortedTags = reportTags ? [...reportTags].sort(sortTagsAlphabetically) : []
  return (
    sortedTags.length > 0 &&
    (isEmployeeView ||
      sortedTags.some((reportTag) => !reportTag?.tag?.is_internal)) && (
      <TagGroup
        data={sortedTags
          .filter((reportTag) => isEmployeeView || !reportTag?.tag?.is_internal)
          .map((reportTag) => reportTag?.tag?.name)}
      />
    )
  )
}

const ReportPreview = ({ cellInfo }) => {
  const versionedScreenshotInfo = getVersionedScreenshotInfo(
    { alert: cellInfo.value },
    WEB2_SCREENSHOTS_BUCKET,
    true,
  )
  const flaggedPreviewImage = versionedScreenshotInfo
    ? constructGcsUrl(versionedScreenshotInfo.bucketName, versionedScreenshotInfo.name)
    : null
  const fallbackPreviewImage = getFlaggedPreviewImage(cellInfo.value)

  return (
    <Flex align="start">
      <TablePreview
        fallbackSrc={fallbackPreviewImage}
        previewSize={TablePreviewSize.LARGE}
        src={flaggedPreviewImage}
        width={previewSizeToWidth[TablePreviewSize.LARGE]}
      />
    </Flex>
  )
}

const dateCreatedColumn: ColumnConfig = {
  id: AlertTableColumn.ALERT_CREATED,
  accessor: 'created_at',
  sortType: SortType.created_at,
  filterType: ReportFilterType.DateRange,
  Cell: (cellInfo) => <TableTimestamp date={cellInfo.value} />,
}

const lastActivityColumn: ColumnConfig = {
  id: AlertTableColumn.LAST_ACTIVITY,
  sortType: SortType.last_activity,
  filterType: ReportFilterType.LastActivityDate,
  accessor: (a) => a.collection_report_changes_aggregate?.aggregate?.max?.timestamp,
  Cell: (cellInfo) => <TableTimestamp date={cellInfo.value} />,
}

const idColumn: ColumnConfig = {
  id: AlertTableColumn.ID,
  accessor: (a) => {
    return a
  },
  Cell: (cellInfo) => (
    <Text fontSize={12} width="80px">
      {getExternalReportId(
        cellInfo.value.organization?.abbr_name,
        cellInfo.value.external_id,
      )}
    </Text>
  ),
}

const previewColumn: ColumnConfig = {
  id: AlertTableColumn.PREVIEW,
  accessor: (a) => {
    return a
  },
  Cell: (cellInfo) => <ReportPreview cellInfo={cellInfo} />,
}

const notesColumn: ColumnConfig = {
  id: AlertTableColumn.NOTES,
  accessor: 'notes',
  sortType: SortType.notes,
  Cell: (cellInfo) => (
    <Text fontSize="10" noOfLines={2} width="250px">
      {cellInfo.value}
    </Text>
  ),
  isColumnInitiallyHidden: true,
}

const generateTagsColumn = (isEmployeeView: boolean): ColumnConfig => {
  return {
    id: AlertTableColumn.TAGS,
    accessor: 'report_tags',
    filterType: ReportFilterType.Tag,
    Cell: (cellInfo) => getTagsColumn(cellInfo.value, isEmployeeView),
  }
}
