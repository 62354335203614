import { Flex, Spinner } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import {
  useSelectedPage,
  useSelectedSpoofReport,
} from '../../hooks/spoof_report_table_filters'
import SpoofReportsTableContent from './spoof_reports_table_content'

export function SpoofReportsTable({
  data,
  loading,
  totalPageCount,
  setToRefreshCache,
  isOrgUnifiedView,
}) {
  const [selectedRow, setSelectedRow] = useState(-1)
  const [selectedSpoofReport, setSelectedSpoofReport] = useSelectedSpoofReport()
  const [selectedPage, setSelectedPage] = useSelectedPage()

  useEffect(() => {
    if (!selectedSpoofReport) {
      setSelectedRow(-1)
      return
    }
  }, [selectedSpoofReport])

  useEffect(() => {
    setSelectedRow(-1)
  }, [selectedPage])

  if (loading) {
    return (
      <Flex align="center" height="80vh" justify="center" width="100vw">
        <Spinner />
      </Flex>
    )
  }

  return (
    <SpoofReportsTableContent
      data={data}
      isOrgUnifiedView={isOrgUnifiedView}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      setToRefreshCache={setToRefreshCache}
      totalPageCount={totalPageCount}
    />
  )
}
