import { visibleAlertsFilters } from '@/atoms/alerts_filters'
import { AlertTableColumnLabels } from '@/generated/enums'
import { Order_By } from '@/generated/graphql'
import { useSelectedPage } from '@/hooks/spoof_report_table_filters'
import { useSelectedSortingMechanism } from '@/pages/reports'
import { useAppSettings } from '@/utils/app_settings'
import {
  DOPPEL_DARK_CLICKABLE_HOVER,
  DOPPEL_WHITE,
  DOPPEL_TEXT_GREY,
  DOPPEL_FONT_FAMILY_BODY,
  FONT_SIZE_MEDIUM,
} from '@/utils/style'
import { Box, Flex, MenuButton } from '@chakra-ui/react'
import { useSetAtom } from 'jotai'
import { Dropdown } from '../doppel_design/dropdown'
import { FaArrowUp, FaArrowDown } from 'react-icons/fa'

export const HeaderMenu = ({ id, sortType, filterType }) => {
  const {
    data: appSettings,
    loading: appSettingsLoading,
    update: updateAppSettings,
  } = useAppSettings()
  const [selectedSortingMechanism, setSelectedSortingMechanism] =
    useSelectedSortingMechanism()
  const [selectedPage, setSelectedPage] = useSelectedPage()
  const setSelectedVisibleAlertsFilters = useSetAtom(visibleAlertsFilters)

  const displaySortIcon = (sortType) => {
    if (!sortType) return <></>

    if (selectedSortingMechanism.sortingType === sortType) {
      if (selectedSortingMechanism.orderBy === Order_By.AscNullsLast) {
        return (
          <Box paddingX="0.5">
            <FaArrowUp size="12" />
          </Box>
        )
      } else {
        return (
          <Box paddingX="0.5">
            <FaArrowDown size="12" />
          </Box>
        )
      }
    } else {
      return <></>
    }
  }

  const getSelectedValue = (sortType) => {
    if (!sortType) return ''

    if (selectedSortingMechanism.sortingType === sortType) {
      if (selectedSortingMechanism.orderBy === Order_By.AscNullsLast) {
        return sortType + '_' + Order_By.AscNullsLast
      } else {
        return sortType + '_' + Order_By.DescNullsLast
      }
    } else {
      return ''
    }
  }

  const menuItems = [
    ...(sortType
      ? [
          {
            label: 'Sort Ascending',
            value: sortType + '_' + Order_By.AscNullsLast,
            action: () => {
              setSelectedSortingMechanism({
                sortingType: sortType,
                orderBy: Order_By.AscNullsLast,
              })
              setSelectedPage(1)
            },
          },
          {
            label: 'Sort Descending',
            value: sortType + '_' + Order_By.DescNullsLast,
            action: () => {
              setSelectedSortingMechanism({
                sortingType: sortType,
                orderBy: Order_By.DescNullsLast,
              })
              setSelectedPage(1)
            },
          },
        ]
      : []),
    ...(filterType
      ? [
          {
            label: `Filter by ${AlertTableColumnLabels[id] || id}`,
            value: `filter_${id}`,
            action: () => {
              setSelectedVisibleAlertsFilters((prevFilters) =>
                new Set(prevFilters).add(filterType),
              )
            },
          },
        ]
      : []),
    {
      label: 'Hide Column',
      value: `hide_${id}`,
      action: () => {
        updateAppSettings({
          alert_table_column_override: {
            ...appSettings.alert_table_column_override,
            [id]: false,
          },
        })
      },
    },
  ]

  return (
    <Box marginY={2}>
      <Dropdown
        customMenuButton={
          <MenuButton
            _hover={{ bgColor: DOPPEL_DARK_CLICKABLE_HOVER, borderRadius: 'md' }}
            alignItems="center"
            as={Box}
            color={
              selectedSortingMechanism.sortingType === sortType
                ? DOPPEL_WHITE
                : DOPPEL_TEXT_GREY
            }
            cursor="pointer"
            display="flex"
          >
            <Flex align="center" gap={1.5}>
              <Box
                // All needed to override Chakra default header styling
                fontFamily={DOPPEL_FONT_FAMILY_BODY}
                fontSize={FONT_SIZE_MEDIUM}
                fontWeight="normal"
                letterSpacing="normal"
                textTransform="capitalize"
                whiteSpace="nowrap"
              >
                {AlertTableColumnLabels[id] || id}
              </Box>

              {displaySortIcon(sortType)}
            </Flex>
          </MenuButton>
        }
        items={menuItems}
        onSelect={(value) => menuItems.find((item) => item.value === value)?.action()}
        selectedValue={getSelectedValue(sortType)}
      />
    </Box>
  )
}
