import { Button } from '@chakra-ui/react'
import { useState } from 'react'
import { getOrgData, useEmail, useIsEmployeeView } from '../../hooks/id_token_claims'
import { ReportStatus, ReportType, getStatusLabel } from '../../utils/constants'
import { isReportFormEnabled } from '../../utils/feature_flags'
import {
  DOPPEL_BLACK,
  DOPPEL_CLICKABLE_GREY,
  DOPPEL_CLICKABLE_GREY_SHADE,
  BUTTON_COLOR_PRIMARY,
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_TEXT_BLACK,
  DOPPEL_WHITE,
} from '../../utils/style'
import { useSelectedReportType } from '../../pages/reports'
import { useSelectedSpoofReport } from '../../hooks/spoof_report_table_filters'
import { useSendSlackMessageMutation } from '@/generated/graphql'
import { sendCustomerReportedSlackMessage } from '@/hooks/slack_notifications'

export default function ReportDetailButton({
  currentStatus,
  reportAction,
  updateReportStatusMutation,
  shouldShowLoadingState,
  setReportFormOpen,
  statusIsEnum,
  isNft = false,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [orgData] = getOrgData()
  const [selectedReportType] = useSelectedReportType()
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const [isEmployeeView] = useIsEmployeeView()
  const [sendSlackMessageMutation] = useSendSlackMessageMutation()
  const [email] = useEmail()

  const scheme = getActionButtonScheme(
    reportAction,
    currentStatus,
    statusIsEnum,
    selectedReportType,
  )
  // const isDisabled =
  //   isEmployeeView &&
  //   reportAction == ReportStatus.ENUM_REPORTED &&
  //   !selectedSpoofReport.approvals.some(
  //     (approval) => approval.status === ApprovalsStatus.APPROVED,
  //   )

  const isDisabled = false

  return (
    <Button
      _hover={{
        background: scheme[ActionButtonScheme.hover],
        color: DOPPEL_BLACK,
      }}
      bgColor={scheme[ActionButtonScheme.bg]}
      fontSize={14}
      isDisabled={isDisabled}
      isLoading={isLoading}
      marginLeft={4}
      marginTop={2}
      onClick={() => {
        if (shouldShowLoadingState) {
          setIsLoading(true)
        }
        if (
          reportAction == ReportStatus.REPORTED ||
          reportAction == ReportStatus.ENUM_REPORTED
        ) {
          if (
            (isEmployeeView || isNft) &&
            isReportFormEnabled(orgData.organization_type, selectedReportType)
          ) {
            // Open External/Internal Enforcement Form
            setReportFormOpen(true)
            return
          } else if (!isEmployeeView && !isNft) {
            // Send Slack Message and update Report Status
            sendCustomerReportedSlackMessage(
              sendSlackMessageMutation,
              email,
              orgData,
              selectedSpoofReport?.flagged_url,
              selectedSpoofReport?.id,
              selectedSpoofReport?.platform?.product,
              'Reported',
            )
            updateReportStatusMutation(() => setIsLoading(false))
            return
          }
        }
        updateReportStatusMutation(() => setIsLoading(false))
      }}
      textColor={scheme[ActionButtonScheme.textColor]}
    >
      {scheme[ActionButtonScheme.icon]}

      {scheme[ActionButtonScheme.label]}
    </Button>
  )
}

enum ActionButtonScheme {
  hover = 'hover',
  hoverTextColor = 'hoverTextColor',
  bg = 'bg',
  label = 'label',
  textColor = 'textColor',
  icon = 'icon',
}

// Temporary util function before we migrate reportStatus to enums
function _convertEnumToActionString(reportStatus: ReportStatus): ReportStatus {
  if (reportStatus == ReportStatus.ENUM_NEEDS_CONFIRMATION) {
    return ReportStatus.NEEDS_CONFIRMATION
  }
  return reportStatus
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ') as ReportStatus
}

export function getActionButtonScheme(
  newStatus: ReportStatus,
  currentStatus: ReportStatus,
  statusIsEnum: boolean,
  selectedReportType: ReportType,
) {
  if (statusIsEnum) {
    newStatus = _convertEnumToActionString(newStatus)
    currentStatus = _convertEnumToActionString(currentStatus)
  }
  const scheme = {
    [ActionButtonScheme.hover]: DOPPEL_CLICKABLE_GREY_SHADE,
    [ActionButtonScheme.bg]: DOPPEL_CLICKABLE_GREY,
    [ActionButtonScheme.textColor]: DOPPEL_BLACK,
    [ActionButtonScheme.label]: newStatus.toString(),
  }
  if (internalOnlyActions.includes(newStatus)) {
    scheme[ActionButtonScheme.bg] = DOPPEL_INTERNAL_PURPLE
    scheme[ActionButtonScheme.textColor] = DOPPEL_WHITE
  }
  if (currentStatus === ReportStatus.NEEDS_REVIEW) {
    if (newStatus == ReportStatus.NEEDS_CONFIRMATION) {
      scheme[ActionButtonScheme.label] = 'Needs Confirmation'
    }
  }
  if (newStatus == ReportStatus.APPROVED_DERIVATIVE) {
    scheme[ActionButtonScheme.label] = 'Monitor'
    return scheme
  }
  if (newStatus == ReportStatus.ARCHIVED) {
    scheme[ActionButtonScheme.label] =
      selectedReportType === ReportType.SUSPICIOUS_EMAILS
        ? 'Mark as No Threat Detected'
        : 'Archive'
    return scheme
  }
  if (newStatus == ReportStatus.REPORTED) {
    scheme[ActionButtonScheme.label] =
      selectedReportType === ReportType.DARK_WEB
        ? `Mark ${getStatusLabel(newStatus, selectedReportType)}`
        : selectedReportType === ReportType.SUSPICIOUS_EMAILS
        ? 'Respond'
        : 'Report'
    scheme[ActionButtonScheme.bg] = BUTTON_COLOR_PRIMARY
    scheme[ActionButtonScheme.textColor] = DOPPEL_TEXT_BLACK
    return scheme
  }
  if (newStatus == ReportStatus.INTERNAL_BAD_MATCH) {
    scheme[ActionButtonScheme.label] = 'Internal Bad Match'
    return scheme
  }
  if (newStatus == ReportStatus.NEEDS_REVIEW) {
    if (
      currentStatus == ReportStatus.INTERNAL_REVIEW ||
      currentStatus === ReportStatus.INTERNAL_BAD_MATCH
    ) {
      scheme[ActionButtonScheme.label] = 'Send to Doppel Review'
      scheme[ActionButtonScheme.bg] = BUTTON_COLOR_PRIMARY
      scheme[ActionButtonScheme.textColor] = DOPPEL_TEXT_BLACK
    } else {
      scheme[ActionButtonScheme.label] = 'Back to Doppel Review'
    }

    return scheme
  }
  if (
    currentStatus === ReportStatus.REPORTED &&
    newStatus == ReportStatus.NEEDS_CONFIRMATION
  ) {
    scheme[ActionButtonScheme.label] = 'Back to Needs Confirmation'
    return scheme
  }
  if (newStatus == ReportStatus.INTERNAL_REVIEW) {
    scheme[ActionButtonScheme.label] = 'Back to Internal Review'
    return scheme
  }
  if (newStatus == ReportStatus.RESOLVED) {
    scheme[ActionButtonScheme.label] = `Mark ${getStatusLabel(
      newStatus,
      selectedReportType,
    )}`
  }
  return scheme
}

const isUpperCase = (string) => /^[A-Z]*$/.test(string)
const internalOnlyActions = [
  ReportStatus.INTERNAL_REVIEW,
  ReportStatus.INTERNAL_BAD_MATCH,
  ReportStatus.ENUM_INTERNAL_BAD_MATCH,
  ReportStatus.ENUM_INTERNAL_REVIEW,
  ReportStatus.RESOLVED,
  ReportStatus.ENUM_RESOLVED,
]

export function getAllowedActionButtons(
  currentStatus: ReportStatus,
  isEmployeeView: boolean,
  filterClauses: ((status: ReportStatus) => boolean)[] = [],
): ReportStatus[] {
  if (!currentStatus) {
    return []
  }

  const isCurrentStatusUpperCase = isUpperCase(currentStatus[0])

  return Object.values(ReportStatus).filter((status) => {
    if (isCurrentStatusUpperCase != isUpperCase(status[0])) {
      return false
    }
    if (status == currentStatus) {
      return false
    }
    if (
      status == ReportStatus.ENUM_INTERNAL_ARCHIVED ||
      status == ReportStatus.TESTING
    ) {
      return false
    }

    // Apply all provided filter clauses
    for (const clause of filterClauses) {
      if (!clause(status)) {
        return false
      }
    }

    // return all actions for employees
    if (isEmployeeView) {
      return true
    }
    return !internalOnlyActions.includes(status)
  })
}
