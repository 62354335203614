import { useAtomValue } from 'jotai'
import { alertsTableResultAtom } from '@/utils/detailed_view/alerts_table_context'
import { useRouter } from 'next/router'
import {
  Order_By,
  useGetSpoofingReportsLazyQuery,
  useUpdateSpoofingReportsMutation,
} from '@/generated/graphql'
import { useUserID } from '@/hooks/id_token_claims'
import { Permission, ProductType, ReportChangeEventSource } from '@/generated/enums'
import {
  getDoppelUrlFromId,
  PRODUCT_TYPE_TO_REPORT_TYPE_MAP,
  REPORT_TYPE_TO_PATH,
} from '@/utils/reports/report_utils'
import { ReportStatus } from '@/generated/enums'
import { useIsUserAuthorizedForAction } from '@/utils/permissions'
import { useSelectedReportStatus } from '@/pages/reports'
import DoppelPrimaryButton from '@/components/shared/doppel_primary_button'
import DoppelDefaultButton from '@/components/shared/doppel_default_button'

type AssignNextAlertButtonProps = {
  current_alert_id?: string
  product: ProductType
  isPrimary?: boolean
}

const AssignNextAlertButton: React.FC<AssignNextAlertButtonProps> = ({
  current_alert_id,
  product,
  isPrimary,
}: AssignNextAlertButtonProps) => {
  const [selectedReportStatus, setSelectedReportStatus] = useSelectedReportStatus()
  const router = useRouter()
  const alertsTableResult = useAtomValue(alertsTableResultAtom)
  const [userId] = useUserID()
  const reportType = PRODUCT_TYPE_TO_REPORT_TYPE_MAP[product]
  const baseTableViewHref = `/${REPORT_TYPE_TO_PATH[reportType] || ''}`
  const isSuspiciousEmails = product === ProductType.SUSPICIOUS_EMAILS

  const isUserAuthorizedForAlertQueueUpdate = useIsUserAuthorizedForAction(
    Permission.ALERT_QUEUE_UPDATE,
  )

  const [getSpoofingReports] = useGetSpoofingReportsLazyQuery()
  const [updateSpoofingReportsMutation] = useUpdateSpoofingReportsMutation()

  const handleAssignNextAlert = async () => {
    if (!alertsTableResult?.ids?.length || !userId) {
      router.push(baseTableViewHref)
      return
    }

    // Find the current position in the alerts list
    const currentIndex = current_alert_id
      ? alertsTableResult.ids.indexOf(current_alert_id)
      : -1

    // Get remaining IDs after current position, if current alert not found, use all IDs
    const remainingInternalIds =
      currentIndex >= 0
        ? alertsTableResult.internal_ids.slice(currentIndex + 1)
        : alertsTableResult.internal_ids

    const remainingExternalIds =
      currentIndex >= 0
        ? alertsTableResult.ids.slice(currentIndex + 1)
        : alertsTableResult.ids

    if (remainingInternalIds.length === 0) {
      router.push(baseTableViewHref)
      return
    }

    const { data: alertsData } = await getSpoofingReports({
      variables: {
        spoofingReportsWhere: {
          id: { _in: remainingInternalIds },
        },
        orderBy: [{ created_at: Order_By.Desc }],
      },
    })

    if (!alertsData?.spoofing_reports) {
      router.push(baseTableViewHref)
      return
    }

    // Create a set of unassigned alert IDs
    const unassignedSet = new Set(
      alertsData.spoofing_reports
        .filter((alert) => !alert.internal_alert_assignee)
        .map((alert) => alert.id),
    )

    // Find first unassigned alert ID from remaining ID s
    const nextUnassignedAlertIdIndex = remainingInternalIds.findIndex((id) =>
      unassignedSet.has(id),
    )

    if (nextUnassignedAlertIdIndex === -1) {
      router.push(baseTableViewHref)
      return
    }

    const nextUnassignedAlertInternalId =
      remainingInternalIds[nextUnassignedAlertIdIndex]
    const nextUnassignedAlertExternalId =
      remainingExternalIds[nextUnassignedAlertIdIndex]

    // Assign current user to the alert
    await updateSpoofingReportsMutation({
      variables: {
        input: {
          report_ids: [nextUnassignedAlertInternalId],
          update_source: ReportChangeEventSource.UI,
          internal_assignee_id: userId,
        },
      },
      update: (cache, { data: { update_reports_action } }) => {
        // Update the cache for the assigned alert
        cache.modify({
          id: cache.identify({
            __typename: 'spoofing_reports',
            id: nextUnassignedAlertInternalId,
          }),
          fields: {
            internal_alert_assignee: () =>
              update_reports_action?.updated_fields?.internal_alert_assignee,
          },
        })
      },
    })

    // Navigate to the alert
    router.push(getDoppelUrlFromId(nextUnassignedAlertExternalId, isSuspiciousEmails))
  }

  if (
    ![ReportStatus.NEEDS_REVIEW, ReportStatus.NEEDS_ACTION].includes(
      selectedReportStatus,
    ) ||
    !isSuspiciousEmails ||
    !isUserAuthorizedForAlertQueueUpdate
  ) {
    return null
  }
  // Only show button if we have table results
  if (!alertsTableResult?.ids?.length) return null

  const buttonText = 'Assign Next Alert'

  return isPrimary ? (
    <DoppelPrimaryButton isPrimaryColor onClick={handleAssignNextAlert}>
      {buttonText}
    </DoppelPrimaryButton>
  ) : (
    <DoppelDefaultButton height="36px" onClick={handleAssignNextAlert}>
      {buttonText}
    </DoppelDefaultButton>
  )
}

export default AssignNextAlertButton
