import { useState } from 'react'
import { VStack, Box, Text, useToast } from '@chakra-ui/react'
import DropdownSelector from '../shared/dropdown_selector'
import { DOPPEL_TEXT_GREY } from '@/utils/style'
import { AlertLinkRelationship, AlertLinkType } from '@/generated/enums'
import DoppelModal from '@/components/doppel_design/doppel_modal'
import { useCreateAlertLinkMutation } from '@/generated/graphql'
import { AUTOGENERATED_LINK_TYPES } from '@/utils/detailed_view/alert_linking_utils'
import AddObjectButton from '../detailed_view/add_object_button'
import { AlertLookup } from './alert_lookup'
import DoppelPrimaryButton from '../shared/doppel_primary_button'

type CreateAlertLinkProps = {
  alertIds: string[]
  alertUuids: string[]
  refetchAlertLinks?: () => Promise<any>
  isBulkActionsView?: boolean
}

type CreateAlertLinkModalBodyProps = {
  alertIds: string[]
  linkedAlertId: string
  setLinkedAlertId: (value: string) => void
  alertLinkType: AlertLinkType | ''
  setAlertLinkType: (value: AlertLinkType | '') => void
  alertLinkRelationship: AlertLinkRelationship | ''
  setAlertLinkRelationship: (value: AlertLinkRelationship | '') => void
  setResolvedAlertId: (value: string) => void
}

const FONT_SIZE = 16
const INPUTS_HEIGHT = '48px'
const BORDER_RADIUS = 8

const CreateAlertLinkModalBody = ({
  alertIds,
  linkedAlertId,
  setLinkedAlertId,
  alertLinkType,
  setAlertLinkType,
  alertLinkRelationship,
  setAlertLinkRelationship,
  setResolvedAlertId,
}: CreateAlertLinkModalBodyProps) => {
  return (
    <VStack align="left" spacing={4}>
      <Box>
        <Text fontSize={FONT_SIZE} fontWeight={600} pb={1}>
          Alert ID
        </Text>

        <AlertLookup
          borderRadius={BORDER_RADIUS}
          fontSize={FONT_SIZE}
          height={INPUTS_HEIGHT}
          onChange={setLinkedAlertId}
          onResolvedId={(alert) => setResolvedAlertId(alert.id)}
          placeholder="Enter Alert ID"
          value={linkedAlertId}
          width="full"
        />
      </Box>

      <Box>
        <Text fontSize={FONT_SIZE} fontWeight={600} pb={1}>
          Link Type
        </Text>

        <DropdownSelector
          borderRadius={BORDER_RADIUS}
          cursor="pointer"
          fontSize={FONT_SIZE}
          fontWeight={400}
          height={INPUTS_HEIGHT}
          items={Object.values(AlertLinkType).filter(
            (type) => !AUTOGENERATED_LINK_TYPES.includes(type),
          )}
          onItemSelected={(value) => setAlertLinkType(value)}
          placeholder="Type"
          selectedItem={alertLinkType}
          width="full"
          {...(alertLinkType === '' && { color: DOPPEL_TEXT_GREY })} // workaround from https://github.com/chakra-ui/chakra-ui/discussions/3783#discussioncomment-5399975 since chakra selector doesn't support placeholder styling
        />
      </Box>

      <Box>
        <Text fontSize={FONT_SIZE} fontWeight={600} pb={1}>
          Relationship
        </Text>

        <DropdownSelector
          borderRadius={BORDER_RADIUS}
          cursor="pointer"
          fontSize={FONT_SIZE}
          fontWeight={400}
          height={INPUTS_HEIGHT}
          idField="value"
          items={[
            {
              value: AlertLinkRelationship.PEER_TO_PEER,
              label: `Peer of selected alert(s)`,
            },
            {
              value: AlertLinkRelationship.PARENT,
              label: `Parent of selected alert(s)`,
            },
            { value: AlertLinkRelationship.CHILD, label: `Child of selected alert(s)` },
          ]}
          nameField="label"
          onItemSelected={(value) => setAlertLinkRelationship(value)}
          placeholder="Select"
          selectedItem={alertLinkRelationship}
          width="full"
          {...(alertLinkRelationship === '' && { color: DOPPEL_TEXT_GREY })}
        />
      </Box>
    </VStack>
  )
}

const CreateAlertLinkButton = ({
  alertIds,
  alertUuids,
  refetchAlertLinks,
  isBulkActionsView = false,
}: CreateAlertLinkProps) => {
  const [openNewAlertLinkModal, setOpenNewAlertLinkModal] = useState(false)
  const [linkedAlertId, setLinkedAlertId] = useState('')

  const [resolvedAlertId, setResolvedAlertId] = useState<string>('')
  const [alertLinkType, setAlertLinkType] = useState<AlertLinkType | ''>('')
  const [alertLinkRelationship, setAlertLinkRelationship] = useState<
    AlertLinkRelationship | ''
  >('')

  const toast = useToast()

  const resetState = () => {
    setOpenNewAlertLinkModal(false)
    setLinkedAlertId('')
    setAlertLinkType('')
    setAlertLinkRelationship('')
  }

  const [createAlertLinkMutation] = useCreateAlertLinkMutation()

  const createAlertLink = async () => {
    if (!resolvedAlertId) {
      return
    }

    try {
      const results = await Promise.all(
        alertUuids.map((alertUuid) =>
          createAlertLinkMutation({
            variables: {
              alert_id_1:
                alertLinkRelationship === AlertLinkRelationship.CHILD
                  ? alertUuid
                  : resolvedAlertId,
              alert_id_2:
                alertLinkRelationship === AlertLinkRelationship.CHILD
                  ? resolvedAlertId
                  : alertUuid,
              type: alertLinkType,
              relationship:
                alertLinkRelationship === AlertLinkRelationship.PEER_TO_PEER
                  ? AlertLinkRelationship.PEER_TO_PEER
                  : AlertLinkRelationship.PARENT_CHILD,
            },
          }),
        ),
      )

      resetState()
      toast({
        title: 'Successfully created alert links',
        status: 'success',
        isClosable: true,
      })
      refetchAlertLinks && refetchAlertLinks()
      return results
    } catch (error) {
      setOpenNewAlertLinkModal(false)
      toast({
        title: 'Error creating alert link',
        description: error.message,
        status: 'error',
        isClosable: true,
      })
    }
  }

  return (
    <>
      {isBulkActionsView ? (
        <DoppelPrimaryButton
          fontSize="11"
          h="30px"
          isInternal
          onClick={() => setOpenNewAlertLinkModal(true)}
        >
          Bulk Alert Link
        </DoppelPrimaryButton>
      ) : (
        <AddObjectButton
          label="Add Alert Link"
          onClick={() => setOpenNewAlertLinkModal(true)}
        />
      )}

      {openNewAlertLinkModal && (
        <DoppelModal
          body={
            <CreateAlertLinkModalBody
              alertIds={alertIds}
              alertLinkRelationship={alertLinkRelationship}
              alertLinkType={alertLinkType}
              linkedAlertId={linkedAlertId}
              setAlertLinkRelationship={setAlertLinkRelationship}
              setAlertLinkType={setAlertLinkType}
              setLinkedAlertId={setLinkedAlertId}
              setResolvedAlertId={setResolvedAlertId}
            />
          }
          isAsync
          isDisabled={!resolvedAlertId || alertUuids.includes(resolvedAlertId)}
          isOpen={openNewAlertLinkModal}
          onClose={() => {
            resetState()
          }}
          primaryAction={createAlertLink}
          primaryLabel="Add"
          title={'Add Linked Alert'}
        />
      )}
    </>
  )
}

export default CreateAlertLinkButton
